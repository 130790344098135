//Dependencies
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
//import Storage from "../../services/localStorage.service";
import Layout from "../../components/layouts";

// Internationalization
import { useTranslation } from 'react-i18next';

// Styles
import * as thankyouStyles from "./thankyou.module.scss"
import * as styles from "./os.module.scss";

// Assets


const Tracking = ({location, pageContext}) => {
    //States
    const [ingridConfig, setIngridConfig] = useState(false)

    //Load Ingrid script
    useEffect(() => {
        const externalScript = document.createElement("script");
        externalScript.async = true;
        externalScript.src = process.env.INGRID_CDN;
        document.body.appendChild(externalScript);
    }, [])

    useEffect(() => {
        if ( window.IngridTrackingWidget && ingridConfig ) {
            const container = document.getElementById('cd-tracking-widget');
            container.innerHTML = '';
            window.IngridTrackingWidget.config(ingridConfig);
        } 
    }, [ingridConfig])

    const orderInput = useRef(null);

    // Translation Function
    const { t } = useTranslation();

    const ingridLocale = {
        se: "sv-SE",
        dk: "da-DK",
        eu: "en-US",
        no: "no-NO"
    }

    const searchOrder = () => {
        //Call netlify function to get token and shit
        const settings = {
            method: 'post',
            url: '/.netlify/functions/tracking/',
            data: {
                resource: orderInput.current.value,
            }
        }

        axios(settings).then((response) => {
            if ( response.status === 200 ) {
                const config = {
                    siteId: process.env.INGRID_SITE_ID,
                    externalId: settings.data.resource,
                    elementId: 'cd-tracking-widget',
                    locale: ingridLocale[pageContext.country],
                    authToken: response.data
                }
                setIngridConfig(config);
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <Layout country={pageContext.country} location={location}>
            <div className={thankyouStyles.cdTrackingPage}>
                <div className="cd-max-width">
                    <div className={thankyouStyles.cdThankyouContainer}>
                        <div className={thankyouStyles.cdTrackingContainer}>
                            <h3>{t('tracking.headline')}</h3>

                             {! ingridConfig ? <div className={`${thankyouStyles.cdTrackingBox}`}>
                                <div className={styles.cdOsSignupLeft}>
                                    <h3>{t('tracking.subtitle')}</h3>
                                </div>
                                <div className={`${thankyouStyles.cdTrackingBoxInputs} ${styles.cdOsForm}`} >
                                    <input
                                        required={true}
                                        className={styles.cdSignupInput}
                                        type="text" ref={orderInput}
                                        placeholder={t('tracking.placeholder')}
                                    />
                                    <button className={styles.cdSignupButton} onClick={() => {searchOrder()}}>{t('tracking.search')}</button>
                                </div>
                            </div>
                            :
                            <>
                                <div className={thankyouStyles.cdTrackingBack} onClick={() => {setIngridConfig(false)}}>
                                    <i className="material-icons">keyboard_backspace</i>
                                    <span>{t('tracking.back')}</span>
                                </div>
                                <div id="cd-tracking-widget"></div>
                            </>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Tracking